<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <div class="vx-col w-full mb-base">
    <vx-card>
      <div class="vx-row">
        <div v-if="sale.customer" class="vx-col sm:w-1/3 w-full mb-2">
          <small class="ml-1 mt-10 w-full"> On Credit </small>
          <vs-switch v-model="onCredit" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <small class="ml-1">Branch</small>
          <div v-if="this.$acl.not.check('admin')" class="pt-2">
            {{ sale.branch != null ? sale.branch.branch_name : 'N/A' }}
          </div>
          <v-select
            v-if="this.$acl.check('admin')"
            v-model="sale.branch"
            v-on:change="
              () => {
                resetData();
                addRow();
                customerDataLoaded = true;
              }
            "
            :options="branchOptions"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
            label="branch_name"
          />
        </div>
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <small class="ml-1">Payment Option</small>
          <v-select
            v-model="sale.payment_method"
            :options="paymentMethods"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="name"
          /><br />
        </div>
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <small class="ml-1">Customer</small>
          <v-select
            :loading="!this.customerDataLoaded"
            v-model="sale.customer"
            :options="customerOptions"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          /><br />
        </div>
        <div class="vx-col sm:w-1/3 w-full mb-2">
          <small class="ml-1">Currency</small>
          <v-select
            v-model="sale.currencyCode"
            :options="
              ($root.settingsData?.data?.alternativeCurrency || [])
                .concat([
                  {
                    currencyCode: $root.businessData.data.currency,
                    conversionRate: 1,
                  },
                ])
                .map((c) => c.currencyCode)
            "
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          /><br />
        </div>
      </div>
      <template v-if="sale.branch != null">
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-divider>ITEMS</vs-divider>
            <vs-table :data="sale" hover-flat>
              <template slot="thead">
                <vs-th>Item / Code</vs-th>
                <vs-th>Price</vs-th>
                <vs-th>Quantity</vs-th>
                <vs-th>Discount</vs-th>
                <vs-th>Amount</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template>
                <vs-tr
                  v-for="(tr, index) in products_selected"
                  :key="index + tr"
                >
                  <vs-td width="2000">
                    <v-select
                      v-model="products_selected[index].product"
                      :options="products"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      label="product_name"
                      class="w-full"
                      :appendToBody="true"
                      :createOption="createProduct"
                    />
                    <v-select
                      v-model="products_selected[index].product"
                      :options="products"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="pt-1 w-full"
                      label="product_code"
                      :appendToBody="true"
                    />
                  </vs-td>
                  <vs-td>
                    <vx-tooltip
                      :text="
                        'Last Price is ' +
                        products_selected[index].product_cost_price
                      "
                      position="top"
                    >
                      <vs-input
                        v-validate="
                          'required|min_value:' +
                          products_selected[index].product_cost_price
                        "
                        :disabled="
                          products_selected[index].product == undefined
                        "
                        :name="'product-' + index + '-price'"
                        :placeholder="
                          'Last Price: ' +
                          products_selected[index].product_cost_price
                        "
                        v-model="products_selected[index].product_sale_price"
                        class="inputx"
                        @input="updatePriceR(tr.index)"
                      />
                      <span
                        v-show="errors.has('product-' + index + '-price')"
                        class="text-danger text-sm"
                        >{{ errors.first('product-' + index + '-price') }}</span
                      >
                    </vx-tooltip>
                  </vs-td>
                  <vs-td>
                    <vx-tooltip
                      :text="
                        'Remaining stock is ' +
                        products_selected[index].product_stock
                      "
                      position="top"
                    >
                      <vs-input
                        v-validate="
                          'required|min_value:0|max_value:' +
                          products_selected[index].product_stock
                        "
                        :disabled="
                          products_selected[index].product == undefined &&
                          products_selected[index].product_stock < 0.5
                        "
                        :name="'product-' + index + '-quantity'"
                        :placeholder="
                          'Remaining Stock: ' +
                          products_selected[index].product_stock
                        "
                        v-model="products_selected[index].product_quantity"
                        @input="updatePrice(tr.index)"
                      />
                      <span
                        v-show="errors.has('product-' + index + '-quantity')"
                        class="text-danger text-sm"
                        >{{
                          errors.first('product-' + index + '-quantity')
                        }}</span
                      >
                    </vx-tooltip>
                  </vs-td>
                  <vs-td>
                    {{
                      Number(
                        tr.product_rtotal - tr.product_total,
                      ).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: sale.currencyCode,
                      })
                    }}</vs-td
                  >
                  <vs-td>{{
                    Number(tr.product_total).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: sale.currencyCode,
                    })
                  }}</vs-td>
                  <vs-td>
                    <feather-icon
                      v-if="products_selected.length > 1 && index != 0"
                      icon="XCircleIcon"
                      svg-classes="w-8 h-8 hover:text-primary stroke-current cursor-pointer"
                      @click.stop="removeRow(index)"
                    />
                    <!-- <feather-icon
                      icon="CopyIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-1"
                      @click.stop="duplicateRow(index)"
                    /> -->
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td>
                    <vs-button
                      color="dark"
                      type="border"
                      @click.stop="() => addRow()"
                      ><feather-icon
                        icon="PlusIcon"
                        svg-classes="w-5 h-5 hover:text-primary stroke-current cursor-pointer"
                      />
                      <div class="mx-2">Add Row</div>
                    </vs-button>
                  </vs-td>
                  <vs-td /> <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />
                </vs-tr>
                <vs-tr>
                  <vs-td /> <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td
                /></vs-tr>
                <vs-tr>
                  <vs-td /> <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />

                  <vs-td
                /></vs-tr>
                <vs-tr>
                  <vs-td /> <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />

                  <vs-td
                /></vs-tr>
                <vs-tr>
                  <vs-td /> <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />

                  <vs-td
                /></vs-tr>
              </template>
            </vs-table>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-textarea
                  v-model="notes"
                  class="mb-10 mt-10"
                  counter="250"
                  maxlength="250"
                  rows="1"
                  label="Add customer notes"
                />
              </div>
            </div>
            <vs-table
              :data="sale"
              hover-flat
              class="w-1/2 ml-auto mt-4 justify-items-end justify-end"
            >
              <vs-tr>
                <th>SUBTOTAL</th>
                <td class="justify-end">
                  {{
                    Number(
                      total > sale.subtotal ? total : sale.subtotal,
                    ).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: sale.currencyCode,
                    })
                  }}
                </td>
              </vs-tr>
              <vs-tr>
                <th>
                  DISCOUNT ({{
                    parseInt(sale.discount).toLocaleString('en-KE')
                  }})%
                </th>
                <td>
                  {{
                    Number(sale.discountCash).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: sale.currencyCode,
                    })
                  }}
                </td>
              </vs-tr>
              <vs-tr>
                <th>TOTAL</th>
                <td>
                  <h3 class="light-gray font-bold">
                    {{
                      Number(total).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: sale.currencyCode,
                      })
                    }}
                  </h3>
                </td>
              </vs-tr>
              <vs-tr
                v-if="
                  this.$root.businessData.data.currency !==
                  this.sale.currencyCode
                "
              >
                <th>TOTAL IN {{ this.$root.businessData.data.currency }}</th>
                <td>
                  <h3 class="light-gray font-bold">
                    {{
                      Number(totalConverted).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: this.$root.businessData.data.currency,
                      })
                    }}
                  </h3>
                </td>
              </vs-tr>
            </vs-table>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              :disabled="!isFormValid || loading"
              class="mr-6"
              @click.stop="submitData"
              >Submit</vs-button
            >
          </div>
        </div>
      </template>

      <template v-else>
        <vs-alert
          v-if="this.$acl.check('admin')"
          title="Pro Tip"
          active="false"
          class="mt-5"
        >
          You have to select a branch for this sale because you are logged in as
          admin. Select a branch to continue.
        </vs-alert>
      </template>
      <div v-if="this.$acl.not.check('admin')">
        {{ getMyBranch }}
      </div>
    </vx-card>
  </div>
</template>

<script>
import idb from '@/db/idb';
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';

const { endpoint, activationFee } = require('@/../config.js');

const initialData = () => ({
  rtotal: 0,
  selected: [],
  itemsPerPage: 4,
  isMounted: false,
  loading: false,
  sale: {
    currencyCode: 'KES',
    branch: null,
    products: [],
    discount: 0,
    discountCash: 0,
    subtotal: 0,
    payment_method: null,
    note: '',
    amount: 0,
    customer: null,
    isWholesale: false,
  },
  addNewDataSidebar: false,
  sidebarData: {},

  customerDataLoaded: false,
  onCredit: false,

  notes: '',
});

export default {
  components: {
    'v-select': vSelect,
    Datepicker,
  },
  data() {
    return initialData();
  },
  pouch: {
    productData() {
      return {
        database: 'express',
        selector: { type: 'product' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    POData() {
      return {
        database: 'express',
        selector: { type: 'PO', status: true },
      };
    },
    supplierData() {
      return {
        database: 'express',
        selector: { type: 'supplier' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
        sort: [
          {
            branch_name: 'asc',
          },
        ],
      };
    },
    customerData() {
      return {
        database: 'express',
        selector: { type: 'customer' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    stockData() {
      return {
        database: 'express',
        selector: {
          type: 'stock',
          // branch_id: this.currentBranch || undefined,
        },
      };
    },
  },
  computed: {
    user() {
      return this.$session.get('user');
    },
    total() {
      var total = 0;
      var rtotal = 0;
      for (var i = 0; i < this.products_selected.length; i++) {
        if (this.products_selected[i].product) {
          total = total + this.products_selected[i].product_total;
          rtotal = rtotal + this.products_selected[i].product_rtotal;
        }
      }
      this.sale.subtotal = rtotal;
      this.rtotal = rtotal;
      if (rtotal > total) {
        this.sale.discountCash = rtotal - total;
        this.sale.discount =
          (this.sale.discountCash / this.sale.subtotal) * 100;
      } else {
        this.sale.discountCash = 0;
        this.sale.discount = 0;
      }
      this.sale.amount = total;
      return total;
    },
    totalConverted() {
      const currency = this.$root.settingsData?.data?.alternativeCurrency.find(
        (cur) => cur.currencyCode === this.sale.currencyCode,
      );
      if (currency) {
        const totalConverted = this.total * currency.conversionRate;
        return totalConverted;
      } else {
        return 0;
      }
    },
    isPaymentValid() {
      return this.sale.payment_method;
      // || (!this.sale.payment_method && this.onCredit && this.sale.customer)
    },
    isFormValid() {
      return (
        !this.errors.any() && this.isPaymentValid && this.sale.subtotal > 0
      );
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    branchOptions() {
      var p = this.branchData ? this.branchData : [];
      if (p.length == 1) {
        this.sale.branch = p[0];
      }
      return p;
    },
    paymentMethods() {
      if (this.sale.branch) {
        this.sale.payment_method = null;
      }

      return this.POData
        ? this.sale.branch
          ? this.POData.map((option) => {
              if (option.branches == undefined) {
                return option;
              }
              if (option.branches.hasOwnProperty(this.sale.branch._id)) {
                if (option.branches[this.sale.branch._id]) {
                  return option;
                }
              }
            }).filter((v) => v)
          : this.POData
        : [];
    },
    customerOptions() {
      const customerData = (this.customerData || []).map((cd) => {
        return {
          ...cd,
          label: `${cd.name}${cd.phone ? ` - ${cd.phone}` : ''}`,
        };
      });

      if (this.$route.params.customerId && customerData) {
        this.sale.customer = customerData.find(
          (c) => c._id === this.$route.params.customerId,
        );
      }

      this.customerDataLoaded = true;

      return customerData;
    },
    all_products() {
      return this.productData ? this.productData : [];
    },
    products() {
      var productData = this.productData ? this.productData : [];
      var stockData = this.stockData ? this.stockData : [];
      var productsFilter = [];
      if (this.productData != null) {
        var productsFinal = [];
        if (this.sale.branch != null) {
          for (var i = 0; i < this.sale.products.length; i++) {
            var pp = this.sale.products[i];
            if (pp && pp.product != undefined) {
              productsFinal.push(pp.product);
            }
          }

          for (let i = 0; i < productData.length; i++) {
            switch (productData[i].version) {
              case 2:
                // V2 item
                productData[i].stockv2 =
                  stockData.filter((e) => e.item_id === productData[i]._id) ||
                  [];
                break;

              default:
                break;
            }
          }

          const productsFilter1 = productData.filter(
            (el) => !productsFinal.find((e) => e._id == el._id),
          );
          // v1
          const productsFilterV1 = productsFilter1.filter((e) => {
            if (e.stock != null) {
              return Object.keys(e.stock).includes(this.sale.branch._id);
            }
          });

          const productsFilterV2 = productsFilter1.filter((e) => {
            if (e.stockv2 != null) {
              return e.stockv2.filter(
                (e) => e.branch_id === this.sale.branch._id,
              ).length;
            }
          });

          productsFilter = productsFilterV1;
          productsFilter = productsFilter.concat(productsFilterV2);
        }
      }
      if (this.sale.currencyCode === this.$root.businessData.data.currency) {
        return productsFilter;
      } else {
        // Return only products that have the currency
        const d = productsFilter
          .map((pd) => {
            if ('prices' in pd) {
              if (this.sale.currencyCode in pd.prices) {
                const { cost_price, retail_price, wholesale_price } =
                  pd.prices[this.sale.currencyCode];
                return {
                  ...pd,
                  cost_price,
                  retail_price,
                  wholesale_price,
                };
              }
            }
          })
          .filter((pd) => !!pd);

        return d;
      }
    },
    products_selected() {
      var d = this.sale.products;
      var p = [];
      for (let i = 0; i < d.length; i++) {
        const e = d[i];
        if (
          e != undefined &&
          e.product != undefined &&
          typeof e.product === 'object' &&
          typeof e.product.stock === 'object'
        ) {
          var c = Object.keys(e.product.stock).includes(this.sale.branch._id);
          if (c === true) {
            e.index = i;

            const row = this.getProductData(i);
            if (row) {
              this.sale.products.splice(i, 1, row);
            }

            let productData = e;

            p.push(productData);
          }
        } else {
          if (e && e.product && e.product.version == 2) {
            var stockv2Check = e.product.stockv2.find(
              (e) => e.branch_id == this.sale.branch._id,
            );
            if (stockv2Check) {
              e.index = i;
              const row = this.getProductData(i);
              if (row) {
                // this.sale.products.splice(i, 1, row);
              }
              let productData = e;

              // if (
              //   this.sale.currencyCode !== this.$root.businessData.data.currency
              // ) {
              //   if (
              //     'prices' in productData.product &&
              //     this.sale.currencyCode in productData.product.prices
              //   ) {
              //     // Change cost, retail and Wholesale
              //     const { cost_price, retail_price, wholesale_price } =
              //       productData.product?.prices[this.sale.currencyCode];

              //     productData = {
              //       ...productData,
              //       product_price: retail_price,
              //       product_retail_price: retail_price,
              //       product_cost_price: cost_price,
              //       product_wholesale_price: wholesale_price,
              //     };

              //     productData.product = {
              //       ...productData.product,
              //       product_price: retail_price,
              //       product_retail_price: retail_price,
              //       product_sale_price: retail_price,
              //       product_wholesale_price: wholesale_price,
              //     };

              //     console.log('productData', productData);
              //   }
              // }

              p.push(productData);
            }
          } else {
            // Product is undefined
            // It could be deleted or not yet selected
            if (e && e.product === undefined) {
              p.push(e);
            }
          }
        }
      }

      if (p.length < 1) {
        // p.push(this.getRowObj());
      }

      return p;
    },
    getMyBranch() {
      this.sale.branch = this.branchOptions.find(
        (e) => e._id === this.$session.get('user').branch,
      );
    },
  },
  watch: {
    // onCredit() {
    //   this.sale.payment_method = null;
    // },
    'sale.currencyCode'() {
      this.resetProducts();
    },
  },
  async created() {
    // this.sale.branch = this.getMyBranch;
    // this.addRow();

    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  mounted() {
    this.sale.currencyCode = this.$root.businessData.data.currency;
    this.sale.branch = this.getMyBranch;
    this.addRow();

    this.isMounted = true;
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);

    // document.onkeydown = this.onKeyPress
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false);

    // document.onkeydown = undefined
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  methods: {
    resetProducts() {
      this.sale.products = [];
      this.addRow();
    },
    resetData() {
      const data = initialData();
      this.sale.currencyCode = this.$root.businessData.data.currency;
      Object.keys(data).forEach((k) => (this[k] = data[k]));
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      const product = this.all_products.find(
        (e) => e && e.product_code == barcode.replace('Enter', ''),
      );

      if (product !== undefined) {
        // Check if item is already in the List
        const itemSearch = this.products_selected.find(
          (e) => e.product_id === product._id,
        );
        const itemIndex = this.products_selected.indexOf(itemSearch);

        if (itemIndex >= 0) {
          // Item is in list, update quantity
          this.sale.products[itemIndex].product_quantity =
            this.sale.products[itemIndex].product_quantity + 1;
        } else {
          const firstItem =
            this.products_selected.length == 0
              ? true
              : this.products_selected[0].product
                ? false
                : true;
          if (firstItem) {
            // this.sale.products[0] = this.getRowObj(product);
            this.sale.products.splice(0, 1, this.getRowObj(product));
            // this.addRow(product);
          } else {
            this.addRow(product);
          }
        }
      }
    },
    getDate(x) {
      return this.$moment(x).format('DD MMM YYYY hh:mm:ss');
    },
    async print(saleId) {
      try {
        if (!this.$root.settingsData?.data?.print_sale_receipt) return;
        if (!saleId) return;
        let sale = {};
        await this.$pouch.get(saleId, {}, 'express').then((r) => {
          sale = r;
        });
        this.showMessage(
          'icon-check-circle',
          'warning',
          'Processing Receipt',
          'Please wait as we process this receipt',
        );
        let cashierData = {};
        await this.$pouch.get(sale.cashier, {}, 'express').then((r) => {
          cashierData = r;
        });

        window.ipcRenderer.send('saleReceipt', {
          logo: this.$root.logoBlob
            ? {
                type: this.$root.logoBlob.type,
                buffer: this.$root.logoBuffer,
              }
            : undefined,
          business: this.$root.businessData.data,
          settings: this.$root.settingsData.data,
          duplicate: false,
          sale: {
            ...sale,
            id: sale._id ? sale._id.split(':')[1] : '',
            date: this.getDate(sale.date_created),
            cashier: cashierData,
          },
        });

        if (this.$root.settingsData?.data?.print_duplicate_receipt) {
          window.ipcRenderer.send('saleReceipt', {
            logo: this.$root.logoBlob
              ? {
                  type: this.$root.logoBlob.type,
                  buffer: this.$root.logoBuffer,
                }
              : undefined,
            business: this.$root.businessData.data,
            settings: this.$root.settingsData.data,
            duplicate: true,
            sale: {
              ...sale,
              id: sale._id ? sale._id.split(':')[1] : '',
              date: this.getDate(sale.date_created),
              cashier: cashierData,
            },
          });
        }

        this.showMessage(
          'icon-check-circle',
          'success',
          'Receipt Processed',
          'Check your printer, the receipt has been generated and sent to the printer',
        );
      } catch (error) {
        console.error('print error', error);
      }
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    createProduct() {
      console.log('create');
    },
    getRowObj(product = null) {
      const obj = {
        product: undefined,
        product_id: 0,
        product_name: null,
        product_code: null,
        product_retail_price: null,
        product_wholesale_price: null,
        product_sale_price: null,
        product_price: null,
        product_cost_price: null,
        product_quantity: null,
        product_total: null,
        product_stock: null,
        product_rtotal: null,
      };

      if (!product) {
        return obj;
      }

      obj.product = product;
      obj.product_id = product._id;
      obj.product_code = product.product_code;
      obj.product_stock = product.stock[this.sale.branch._id];
      obj.product_retail_price = product.retail_price;
      obj.product_wholesale_price =
        product.wholesale_price || product.retail_price;
      obj.product_cost_price = product.cost_price;
      obj.product_quantity = 1;

      var isWholesale = this.sale.customer
        ? this.sale.customer.hasOwnProperty('wholesale')
          ? this.sale.customer.wholesale
          : false
        : false;

      obj.product_sale_price = obj.product_retail_price;

      if (isWholesale) {
        obj.product_sale_price = obj.product_wholesale_price;
      }

      obj.isWholesale = isWholesale;
      obj.product_price = product.retail_price;
      obj.product_total = product.retail_price * obj.product_quantity;
      obj.product_rtotal = product.retail_price * obj.product_quantity;

      return obj;
    },
    getProductData(index) {
      var row = this.sale.products[index];
      var customer = this.sale.customer || null;
      var isWholesale = customer
        ? customer.hasOwnProperty('wholesale')
          ? customer.wholesale
          : false
        : false;
      if (row.product != undefined || row.product != null) {
        var product = this.all_products.find((e) => e._id == row.product._id);
        if (product) {
          product.currency = this.$root.businessData.data.currency;
          if (
            this.sale.currencyCode !== this.$root.businessData.data.currency
          ) {
            if (this.sale.currencyCode in product.prices) {
              const { cost_price, retail_price, wholesale_price } =
                product.prices[this.sale.currencyCode];
              product = {
                ...product,
                cost_price,
                retail_price,
                wholesale_price,
                currency: this.sale.currencyCode,
              };
            }
          }
          switch (product.version) {
            case 2:
              row.product_stockv2 = product.stockv2
                ? product.stockv2.find(
                    (e) => e.branch_id == this.sale.branch._id,
                  )
                : [];
              row.product_stock = row.product_stockv2
                ? row.product_stockv2.stock
                : 0;
              break;

            default:
              row.product_stock = product.stock[this.sale.branch._id];
              break;
          }

          if (row.product_stock < 1) {
            row.product_quantity = null;
          } else if (product.version == 2 && row.product_stockv2 < 1) {
            row.product_quantity = null;
          } else {
            if (row.product_quantity == null) {
              row.product_quantity = 1;
            }
          }

          row.product_version = product.version;

          row.product_id = product._id;
          row.product_code = product.product_code;

          row.product_retail_price = product.retail_price;
          row.product_wholesale_price =
            product.wholesale_price || product.retail_price;
          row.product_cost_price = product.cost_price;

          row.isWholesale = isWholesale;

          if (isWholesale) {
            row.product_price = product.wholesale_price || product.retail_price;
            row.product_sale_price =
              product.wholesale_price || product.retail_price;

            row.product_total =
              (product.wholesale_price || product.retail_price) *
              row.product_quantity;
            row.product_rtotal =
              (product.wholesale_price || product.retail_price) *
              row.product_quantity;
          } else {
            row.product_price = product.retail_price;
            row.product_sale_price = product.retail_price;

            row.product_total = product.retail_price * row.product_quantity;
            row.product_rtotal = product.retail_price * row.product_quantity;
          }

          // this.sale.products[index] = row;
          // this.sale.products.splice(index, 1, row);
          return row;
        }
        return null;
      }
      // this.sale.products[index] = this.getRowObj();
      // this.sale.products.splice(index, 1, this.getRowObj());
      return this.getRowObj();
    },
    updatePriceR(index) {
      // if (this.sale.products[index].isWholesale) {
      //   // Is wholesale
      //   if (
      //     this.sale.products[index].product_quantity > 0 &&
      //     this.sale.products[index].product_wholesale_price > 0
      //   ) {
      //     this.sale.products[index].product_total =
      //       this.sale.products[index].product_quantity *
      //       this.sale.products[index].product_wholesale_price;

      //     this.sale.products[index].product_rtotal =
      //       this.sale.products[index].product_quantity *
      //       this.sale.products[index].product_price;
      //   }
      // } else {
      if (
        this.sale.products[index].product_quantity > 0 &&
        this.sale.products[index].product_sale_price > 0
      ) {
        this.sale.products[index].product_total =
          this.sale.products[index].product_quantity *
          this.sale.products[index].product_sale_price;

        this.sale.products[index].product_rtotal =
          this.sale.products[index].product_quantity *
          this.sale.products[index].product_price;
      }
      // }
    },
    updatePrice(index) {
      // if (this.sale.products[index].isWholesale) {
      //   // Is wholesale
      //   if (
      //     this.sale.products[index].product_quantity > 0 &&
      //     this.sale.products[index].product_wholesale_price > 0
      //   ) {
      //     this.sale.products[index].product_total =
      //       this.sale.products[index].product_quantity *
      //       this.sale.products[index].product_wholesale_price;

      //     this.sale.products[index].product_rtotal =
      //       this.sale.products[index].product_quantity *
      //       this.sale.products[index].product_price;
      //   }
      // } else {
      if (
        this.sale.products[index].product_quantity > 0 &&
        this.sale.products[index].product_sale_price > 0
      ) {
        this.sale.products[index].product_total =
          this.sale.products[index].product_quantity *
          this.sale.products[index].product_sale_price;
        this.sale.products[index].product_rtotal =
          this.sale.products[index].product_quantity *
          this.sale.products[index].product_price;
      }
      // }
    },
    duplicateRow(index) {
      this.addRow();
      var last = this.sale.products.slice(-1).pop();
      this.sale.products[this.sale.products.length - 1] =
        this.sale.products[index];
    },
    addRow(product = null) {
      this.sale.products.push(this.getRowObj(product));
    },
    removeRow(id) {
      this.sale.products.splice(id, 1);
    },
    showMessage(icon, color, title, message) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: icon,
        color: color,
      });
    },
    getCustomer(c) {
      if (c !== null) {
        return c.id;
      }
      return;
    },
    submitData() {
      try {
        if (this.isFormValid) {
          this.loading = true;
          this.$vs.notify({
            color: 'warning',
            title: 'Processing',
            text: 'Processing Data',
          });

          var isWholesale = this.sale.customer
            ? this.sale.customer.hasOwnProperty('wholesale')
              ? this.sale.customer.wholesale
              : false
            : false;

          let amount = this.total;
          let conversionRate = 1;

          let obj = {
            customer: this.sale.customer,
            amount,
            amountRaw: amount,
            discount: this.sale.discountCash,
            payment_method: this.sale.payment_method,
            products: this.sale.products,
            branch: this.sale.branch,
            credit: this.onCredit,
            products_raw: [],
            notes: this.notes,
            isWholesale,
            currencyCode:
              this.sale.currencyCode || this.$root.businessData.data.currency,
            converted:
              this.sale.currencyCode !== this.$root.businessData.data.currency,
          };

          // convert other currencies
          if (
            this.sale.currencyCode !== this.$root.businessData.data.currency
          ) {
            const currency =
              this.$root.settingsData?.data?.alternativeCurrency.find(
                (cur) => cur.currencyCode === this.sale.currencyCode,
              );
            if (currency) {
              conversionRate = currency.conversionRate;
              obj.conversionRate = conversionRate;
              const totalConverted = obj.amountRaw * conversionRate;

              obj = {
                ...obj,
                currency,
                amount: totalConverted,
              };
            } else {
              this.showMessage(
                'icon-check-circle',
                'danger',
                `Currency ${this.sale.currencyCode} not available`,
                `Unable to complete sale due to missing currency '${this.sale.currencyCode}''`,
              );
              return;
            }
          }

          obj.discount = parseFloat(obj.discount) * conversionRate;

          for (var i = 0; i < obj.products.length; i++) {
            var p = obj.products[i];
            if (p.product != undefined || p.product != null) {
              switch (p.product.version) {
                case 2:
                  var currentStock = p.product.stockv2.find(
                    (e) =>
                      e.item_id == p.product._id &&
                      e.branch_id == this.sale.branch._id,
                  );
                  var newStock = currentStock
                    ? parseInt(currentStock.stock) -
                      parseInt(p.product_quantity)
                    : null;
                  if (newStock == null || typeof newStock != 'number') {
                    // TODO: throw error
                    throw new Error(
                      `Missing stock data for item '${p.product.product_name}''`,
                    );
                  }
                  if (newStock < 0) {
                    // TODO: throw error
                    this.showMessage(
                      'icon-check-circle',
                      'warning',
                      'Insufficient Stock',
                      `Unable to complete sale due to insufficient stock for item '${p.product.product_name}''`,
                    );

                    continue;
                  } else {
                    // Create new update stock item record
                    currentStock.stock = newStock;
                    console.debug('currentStock', currentStock);
                    // Reject sale if new stock is null
                    if (!Number.isInteger(currentStock.stock)) {
                      throw new Error('Invalid stock entry');
                    }

                    this.$store
                      .dispatch('products/updateProductStock', currentStock)
                      .then((response) => {
                        this.showMessage(
                          'icon-check-circle',
                          'success',
                          'Stock Update Successfully',
                          `${response.id}`,
                        );
                      });

                    obj.products_raw.push({
                      ...p.product,

                      cost_price:
                        parseInt(p.product.cost_price) * conversionRate,
                      retail_price:
                        parseInt(p.product.retail_price) * conversionRate,
                      wholesale_price:
                        parseInt(p.product.wholesale_price) * conversionRate,

                      sale_sub_total:
                        parseInt(p.product_rtotal) * conversionRate,
                      sale_total: parseInt(p.product_total) * conversionRate,
                      sale_price:
                        parseInt(p.product_sale_price) * conversionRate,
                      q: parseInt(p.product_quantity),

                      sale: {
                        sub_total: parseInt(p.product_rtotal) * conversionRate,
                        total: parseInt(p.product_total) * conversionRate,
                        priceEach:
                          parseInt(p.product_sale_price) * conversionRate,
                        quantity: parseInt(p.product_quantity),

                        previous_quantity: currentStock
                          ? parseInt(currentStock.stock) +
                            parseInt(p.product_quantity)
                          : 0,
                      },
                      date_updated: Date.now(),
                    });

                    obj.version = 2;
                  }

                  break;
                default:
                  p.product.stock[this.sale.branch._id] =
                    parseInt(p.product.stock[this.sale.branch._id]) -
                    parseInt(p.product_quantity);

                  obj.products_raw.push({
                    ...p.product,

                    sale_sub_total: parseInt(p.product_rtotal),
                    sale_total: parseInt(p.product_total),
                    sale_price: parseInt(p.product_sale_price),
                    q: parseInt(p.product_quantity),

                    sale: {
                      sub_total: parseInt(p.product_rtotal),
                      total: parseInt(p.product_total),
                      priceEach: parseInt(p.product_sale_price),
                      quantity: parseInt(p.product_quantity),

                      previous_quantity: p.product.stock[this.sale.branch._id],
                    },
                    date_updated: Date.now(),
                  });
              }
            }
          }

          console.debug(obj);
          this.$vs.loading();
          this.$store
            .dispatch('sales/addSale', obj)
            .then((response) => {
              this.showMessage(
                'icon-check-circle',
                'success',
                'Sale Recorded Successfully',
                'You have successfully recorded a new sale',
              );

              for (var i = 0; i < obj.products.length; i++) {
                var p = obj.products[i];

                if (p.product_id != 0) {
                  let previous_quantity = 0;
                  switch (p.product.version) {
                    case 2:
                      var currentStock = (this.stockData || []).find(
                        (e) =>
                          e.item_id == p.product._id &&
                          e.branch_id == this.sale.branch._id,
                      );
                      previous_quantity = currentStock
                        ? parseInt(currentStock.stock) +
                          parseInt(p.product_quantity)
                        : 0;

                      break;

                    default:
                      previous_quantity =
                        parseInt(p.product.stock[this.sale.branch._id]) +
                        parseInt(p.product_quantity);
                      break;
                  }
                  this.$store.dispatch('products/addProductStockUpdate', {
                    product_id: p.product_id,
                    branch_id: this.sale.branch._id,
                    sale_id: response.id,
                    quantity: p.product_quantity,
                    isWholesale,
                    previous_quantity: previous_quantity,
                    action: 'subtract',
                    caller: 'new_sale',
                  });
                }
              }

              // If app is native then try printing receipt
              if (window.ipcRenderer) this.print(response.id);

              if (this.$root.settingsData?.sale_redirect_to_receipt) {
                this.$vs.loading.close();
                this.$router
                  .push(`/store/sales/${response.id}/receipt`)
                  .catch(() => {});
                // this.$router.push("/store/sales").catch(() => {});
              } else {
                this.$vs.loading.close();
                this.resetData();
                this.addRow();
                this.customerDataLoaded = true;
              }
            })
            .catch((err) => {
              this.$vs.loading.close();
              console.error(err);
              this.showMessage('icon-error-circle', 'danger', 'Error', err);
            });
          this.loading = false;
        }
      } catch (error) {
        this.showMessage(
          'icon-check-circle',
          'danger',
          'Something went wrong',
          `Error: ${error.message}`,
        );
      }
    },
    getStatusColor(status) {
      if (status == true) return 'success';
      return 'danger';
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .name {
      max-width: 18rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
